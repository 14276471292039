import { Link } from "react-router";
import { InfoIcon } from "lucide-react"; // Import an icon from lucide-react
import { useTranslation } from "react-i18next";
import TranslatedSubjectName from "../features/worksheets/TranslatedSubjectName";

interface FooterProps {
  subjects: string[];
}

const Footer = ({ subjects }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer className="py-8 bg-gray-800 text-white">
      <div className="container mx-auto flex items-center">
        <p className="mr-4 text-gray-300">
          {t("footer.copy", { year: new Date().getFullYear() })}
        </p>
        {/* Icon between copyright and nav links */}
        <InfoIcon className="mx-2" size={16} />{" "}
        {/* You can adjust the size as needed */}
        <nav className="flex space-x-2">
          <Link to="/p/page/privacy-policy" className="hover:text-teal-400">
            {t("nav.privacy")}
          </Link>
          <span className="text-white">|</span> {/* Separator */}
          <Link to="/p/page/terms-of-service" className="hover:text-teal-400">
            {t("nav.terms")}
          </Link>
          <span className="text-white">|</span> {/* Separator */}
          <Link to="/p/contact" className="hover:text-teal-400">
            {t("nav.contact")}
          </Link>
          <span className="text-white">|</span> {/* Separator */}
          <Link to="https://updown.io/p/l0kid" className="hover:text-teal-400">
            {t("nav.status")}
          </Link>
        </nav>
      </div>

      <div className="text-center mt-12 mb-8 max-w-1/2 mx-auto">
        <h3 className="text-sm font-semibold">{t("footer.subjects.title")}</h3>
        {subjects.map((subject, index) => (
          <span key={subject}>
            <a
              href={`/p/gallery/${subject}`}
              className="text-sm text-white hover:underline"
            >
              <TranslatedSubjectName subject={subject} />
            </a>
            {index < subjects.length - 1 && " · "}
          </span>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
