import { authkitLoader } from "@workos-inc/authkit-remix";
import { LoaderFunctionArgs, Outlet, useLoaderData } from "react-router";
import Footer from "~/components/layout/Footer";
import Header from "~/components/layout/Header";
import i18next from "~/i18next.server";
import { getApiService } from "~/services/api";

export const loader = async (args: LoaderFunctionArgs) => {
  return authkitLoader(args, async () => {
    const api = getApiService();
    const locale = await i18next.getLocale(args.request);
    const subjects = await api.appApiWorksheetsGetSubjectsList(locale);
    return { subjects };
  });
};

export default function P() {
  const { user, subjects } = useLoaderData<typeof loader>();

  return (
    <div className="flex flex-col min-h-screen">
      <Header user={user} />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer subjects={subjects} />
    </div>
  );
}
